<template>
    <div>
        <h4 class="bold text-uppercase mb-3">
            <span @click="$router.go(-1)" class="font-weight-bold hand">
                <i class="fas fa-chevron-left mx-1"></i>
                {{ $route.name }}
            </span>
        </h4>
        <div class="row form-group align-items-center">
            <div class="col-md-auto col-12">
                <h6 class="bold mb-0">Tải tối đa:</h6>
            </div>
            <div class="col-md-auto col-12">
                <select class="form-control input-light" v-model="limitLogs" @change="getToolsLogs()">
                    <option value="100">Xem 100 nhật ký</option>
                    <option value="200">Xem 200 nhật ký</option>
                    <option value="300">Xem 300 nhật ký</option>
                    <option value="500">Xem 500 nhật ký</option>
                    <option value="1000">Xem 1000 nhật ký</option>
                </select>
            </div>
        </div>
        <datatable :stt="true" :columns="columns" :rows="data"> </datatable>
    </div>
</template>
<script>
import { getToolsLogs } from "../../../api/admin-agency-lv2"
import datatable from "@/components/datatable/datatable.vue"
export default {
    name: "report-manager",
    components: {
        datatable
    },
    data() {
        return {
            limitLogs: 100,
            data: [],
            columns: [
                {
                    label: "Hành Động",
                    field: "action"
                },
                {
                    label: "Đối Tượng",
                    field: "object_id",
                    fb_link: true
                },
                {
                    label: "Tool",
                    field: "tool_name"
                },
                {
                    label: "Tiền",
                    field: "coin_html",
                    html: true
                },
                {
                    label: "Nội dung",
                    field: "description",
                    html: true
                },
                {
                    label: "Trạng Thái",
                    field: "status_html",
                    html: true
                },
                {
                    label: "Thời Gian Tạo",
                    field: "created_at",
                    timeago: true
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.agency.site
        },
        reload() {
            return this.$store.state.app.reload
        },
        agency() {
            return this.$store.state.agency
        }
    },
    created() {
        this.getToolsLogs()
    },
    methods: {
        getToolsLogs: async function() {
            let data = await getToolsLogs(this.limitLogs)
            if (data.status === 200 && data.success) {
                this.data = data.data.map(log => {
                    log.status = 1
                    log.status_html =
                        parseInt(log.status) === 1
                            ? "<span class='badge badge-pill badge-success'>Thành công</span>"
                            : parseInt(log.status) === 0
                            ? "<span class='badge badge-pill badge-warning'>Đang chạy </span>"
                            : "<span class='badge badge-pill badge-danger'>Thất bại</span>"
                    let oldCoin = Number(log.old_coin)
                    let newCoin = Number(log.new_coin)
                    let changeCoin = Number(log.new_coin) - Number(log.old_coin)
                    log.changeCoin = changeCoin
                    log.coin_html =
                        "<span class='py-1 badge badge-pill badge-yellow'>" +
                        oldCoin.toLocaleString("it-IT") +
                        "</span>"
                    log.coin_html +=
                        changeCoin >= 0
                            ? " + <span class='py-1 badge badge-pill badge-success'>" +
                              changeCoin.toLocaleString("it-IT") +
                              "</span>"
                            : " - <span class='py-1 badge badge-pill badge-danger'>" +
                              (changeCoin * -1).toLocaleString("it-IT") +
                              "</span>"
                    log.coin_html +=
                        " = <span class='badge badge-pill badge-primary'>" + newCoin.toLocaleString("it-IT") + "</span>"
                    log.actions_html = log.actions ? log.actions.name : log.action
                    log.name = this.user ? this.user.name : ""
                    return log
                })
            }
        }
    }
}
</script>
